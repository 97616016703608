$custom-primary: #2c90d3;
$primary-green: #29aba4;
$primary-dark: #001010;
$primary-light: #fffefe;
$dark: #001010;
$warning: #d06020;
$danger: #d02030;

@import 'layout';
@import 'bootstrap';

// 印刷の設定
@import 'print';

// サイドナビの設定
@import 'sidenav';

// Mathdown
@import 'web_core/assets/mathdown/styles/colorful.scss';
@import 'web_core/assets/mathdown/styles/monotone.scss';
@import 'web_core/assets/mathdown/styles/editor.scss';
