@import 'web_core/styles/bootstrap';

// primaryボタンのデザインを変更
.btn-primary {
  background: linear-gradient(to top right, $primary 20%, $primary-green 80%);
  color: white !important; // :hover時などもデザインを適用するため
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background: linear-gradient(
      to top right,
      #2989c8 20%,
      #3ea29c 80%
    ); // $primary, $primary-green を5%黒くした値
  }
}

// outline-primaryボタンのデザインを変更
.btn-outline-primary {
  &:hover,
  &:active {
    background: linear-gradient(to top right, $primary 20%, $primary-green 80%);
    color: white !important;
  }
  &:focus {
    color: $primary;
  }
}

// primaryボタンのデザインを変更
.bg-primary {
  background: linear-gradient(to right, $primary 20%, $primary-green 80%);
  color: white !important;
}
