.sidenav {
  .nav-link {
    padding-left: 0;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
  }
  .nav-link.active {
    font-weight: bold;
    background-color: #19202a1f;
  }
  .nav-link:not(.active) {
    &:hover,
    &:active,
    &:focus {
      --bs-text-opacity: 0.75;
    }
  }
}
